<template>
  <b-container fluid class="footer_component" :style="($props.background?'background: '+$props.background:'')">
    <b-row v-if="links !== undefined">
      <b-col md="4">
        <img :src="$props.logo" class="align-self-center mt-2 mb-2" width="50%" /><br>
         <img v-if="$props.logo2" :src="$props.logo2" class="align-self-center p-2" width="50%" />
        <div :class="'mb-3 '+($props.textColor?'':'text-white')" :style="($props.textColor?'color:'+$props.textColor:'')">
          <div v-for="(data, index) in $props.info" :key="index" class="text-center small mb-2">
            <b>{{data.name}}</b>
            <div v-html="data.value"></div>
          </div>
        </div>
         <div v-if="$props.socialNetworks" class="h1 mb-2">
           <template v-for="(social, index) in socialNetworks">
             <a :href="social.link" target="_blank" :key="index"><img :src="social.logo" alt="network. networks" width="30px"></a>
             &nbsp;
           </template>
         </div>
      </b-col>
      <b-col :md="($props.cols?$props.cols:'2')" class="pt-4 pl-0 pr-0 mr-0 ml-0" v-for="section in $props.links" :key="section.id">
        <b-list-group  class="border-0 bg-transparent"  >
          <b-list-group-item  v-if="section.footer > 0" :class="'font-weight-bold border-0 border-bottom text-left bg-transparent '+($props.areaTextColor?'':'text-white')" :style="($props.areaTextColor?'color: '+$props.areaTextColor:'')">{{section.name}}</b-list-group-item>
          <b-list-group-item class="border-0 text-left bg-transparent " v-for="item in section.items" :key="item.id">
           <a v-if="item.is_link==0" :href="item.url" :class="($props.sectionTextColor?'':'text-white')" :style="($props.sectionTextColor?'color: '+$props.sectionTextColor:'')">{{item.name}}</a>
            <a v-if="item.is_link==1" :href="item.link" target="_blank" :class="($props.sectionTextColor?'':'text-white')" :style="($props.sectionTextColor?'color: '+$props.sectionTextColor:'')">{{item.name}}</a>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="border-top mt-2">
      <b-col cols="12" md="6">
        <b-nav class="" align="left" >
          <b-nav-item :class="($props.classFooter?$props.classFooter:'footer_item')" :href="'https://'+$props.url"><b>{{$props.name}}</b> - {{$props.slogan}}</b-nav-item>
        </b-nav>
      </b-col>
      <b-col cols="12" md="6" >
        <b-nav class="" align="right">
          <b-nav-text :class="($props.classFooter?$props.classFooter:'footer_item')">Developed by <a href="https://systemico.net" class="font-weight-bold" target="_blank">Systemico Technologies</a> - smart and simple software.</b-nav-text>
        </b-nav>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'FooterComponent',
    props: {
      name: String,
      url: String,
      slogan:String,
      links: Object,
      logo: String,
      logo2: String,
      info: Object,
      background: String,
      textColor: String,
      areaTextColor: String,
      sectionTextColor: String,
      socialNetworks: Object,
      classFooter: String,
      cols: String
    }
  }
</script>
<style lang="scss" scoped>
  .footer_component{
    background: #222222;
    .footer_item{
      font-size: 0.8em;
      color:#fefefe;
    }
    .footer_item a{
      color:#fefefe;
    }
    .footer_item a:hover{
      color:#006df0;
    }
  }
  .footer-laisla{
    font-size: 0.8em;
    color:#222222;
    a{
      color:#222222;
    }
    a:hover{
      color:#006df0;
    }
  }

</style>
